<template>
  <div>
    <div v-if="relatedTasks && relatedTasks.length > 0">
      <ul
        v-if="relatedTasks && relatedTasks.length > 0"
        class="list-unstyled task-header--details row"
      >
        <li class="" style="display: flex">
          <label class="align-right" style="width: 12.5%">Powiązane: </label>
          <span :id="`${task.id}-relatedTasks`" style="width: 77.5%">
            <template v-for="(relatedTask, index) in relatedTasks">
              <popover
                v-if="relatedTasks && relatedTasks.length > 0"
                placement="bottom"
                :title="`${relatedTask.number || relatedTask.id} ${getTitleOfTask(relatedTask.id)}`"
                trigger="hover"
                :key="`popver-${relatedTask.id}`"
              >
                <span
                  v-if="relatedTasks && relatedTasks.length > 0"
                  :key="relatedTask.id"
                  :id="`span${task.id}-related-task-${relatedTask.id}`"
                >
                  <router-link
                    :id="`${task.id}-related-task-link-${
                      relatedTask.number || relatedTask.id
                    }`"
                    class="related-task-link"
                    :to="getRelatedTaskLink(relatedTask.id)"
                  >
                    <a>
                      <span>
                        {{ relatedTask.number || relatedTask.id }}
                      </span>
                    </a>
                  </router-link>
                  <span v-if="getSubtaskRelatedData(relatedTask.id).length">
                    (
                  </span>
                  <router-link
                    v-for="(item, index) in getSubtaskRelatedData(relatedTask.id)"
                    :key="item.id"
                    class="related-task-link"
                    :to="getRelatedAppraisalLink(item.id)"
                  >
                    <a>
                      <span>
                        {{item.number}}
                      </span>
                      <span v-if="index !== getSubtaskRelatedData(relatedTask.id).length - 1">, </span>
                    </a>
                  </router-link>
                  <span v-if="getSubtaskRelatedData(relatedTask.id).length">
                    )
                  </span>
                  {{ getComa(index) }}
                </span>
                <template slot="popover">
                  <ul style="list-style: none">
                    <li v-if="relatedTask.author">
                      <label>Autor:</label> {{ relatedTask.author.label }}
                    </li>
                    <li v-if="relatedTask.createdAt">
                      <label>Utworzono:</label> {{ relatedTask.createdAt }}
                    </li>
                  </ul>
                </template>
              </popover>
              <span v-if="relatedTasks.length === 0" :key="relatedTask.id"
                >b.d.</span
              >
            </template>
          </span>
        </li>
        <li class="col-xs-12" />
      </ul>
    </div>
    <div v-if="relatedTasks.length === 0">
      <label class="align-right col-xs-6" style="width: 12.5%; padding-right:5px"
        >Powiązane:
      </label>
      <span>b.d.</span>
    </div>
  </div>
</template>

<script>
import { Popover } from "uiv";

export default {
  name: "RelatedTasks",
  props: {
    relatedTasks: { type: Array, default: () => [] },
    service: { type: String, default: () => "" },
    workflowName: { type: String, default: () => "" },
    task: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    popover: Popover,
  },
  computed: {
    client() {
      return this.$route.params.client;
    },
  },
  methods: {
    getTitleOfTask(relatedId) {
      if(this.getSubtaskRelatedData(relatedId).length) {
        return `(${this.getSubtaskRelatedData(relatedId).map(item => item.number).join(', ')})`
      }
      return ''
    },
    getSubtaskRelatedData (currentTaskId) {
      const relatedData = (this.task.parent && this.task.parent.subtasks?.length) ? this.task.parent.subtasks
        .filter(el => el.parent == currentTaskId)
        .map(item => {
          return { 
            id: item.id,
            number: item.number
    }
    }) : ''
      return relatedData
    },
    getRelatedTaskLink(id) {
      return {
        name: `${this.service}_task_details`,
            params: {
              id: id,
              service: this.service,
              client: this.client,
              taskCategoryId: '1'
            },
      }
    },
    getRelatedAppraisalLink(id)  {
      return {
        name: `${this.service}_appraisal_task_details`,
        params: {
          id: this.task.parent.id, 
          taskCategoryId: '1',
          appraisalTaskId: id, 
          appraisalTaskCategory: '4',
        }
      }
    },
    getComa(index) {
      if (index === this.relatedTasks.length - 1) {
        return ".";
      } else {
        return ",";
      }
    },
  },
};
</script>

<style scoped>
</style>
