<template>
  <section>
    <section class="content" v-if="dataLoaded">
      <change-state-modal
        :changeStateEvent="events.changeState"
        :taskId="taskId"
        :taskState="task.state ? task.state.name : ''"
        :show="changeStateVisible"
        :simpleTransition="simpleTransition"
        @close="changeStateVisible = false"
        :workflow-id="workflowId"
        :service="service"
        :client="client"
        :category-group="categoryGroup"
        :filePath="taskFilePath"
      >
      </change-state-modal>
      <div class="row">
        <div class="details-screen col-xs-12">
          <task-header
            v-if="task"
            :service="service"
            :dataToShow="taskHeaderData"
            :relatedTasks="relatedTasks"
            :workflowName="workflowName"
            class="floating-task-header"
            :task="task"
            :taskData="taskData"
            :client="clientObject.name"
            @task-header-shadow="m$handleTaskHeaderShadow"
            @task-header-collapsed="m$handleTaskHeaderCollapsed"
          ></task-header>
          <tabs
            ref="tabs"
            class="task-form"
            :tabsData="tabsData"
            @clickedTab="reactToTabChange"
            :topDistance="m$tabsTopDistance"
          >
            <attachments
              v-if="activeTab === 'attachments'"
              slot="attachments"
              :activeTab="activeTab"
              :path="path"
              :filePaths="allTasksFilePaths"
              :filePath="taskFilePath"
              :service="service"
              :subtasks="task.subtasks"
              :taskId="parseInt(taskId)"
              :taskNumber="taskNumber !== null ? taskNumber : ''"
              :taskState="task.state"
              :task-type="workflowName"
              :workflow-id="workflowId"
              @attachments-count-changed="loadNumberOfAttachments"
            ></attachments>
            <div slot="messages">
              <messages
                :service="service"
                :task="task"
              ></messages>
            </div>
            <div slot="history">
              <history
                v-if="activeTab === 'history'"
                :taskUuid="task.uuid"
                :service="service"
              ></history>
            </div>
          </tabs>
        </div>
      </div>
    </section>
    <blocking-loader :show="loadingData">Wczytywanie reklamacji</blocking-loader>
  </section>
</template>

<script>
import Page from '../../../../share/content/Page.js'
import api from '../../../../../api/index.js'
import Tabs from '../../../../share/tabs/Tabs.vue'
import ChangeStateModal from '../../share/ChangeStateModal/ChangeState.vue'
import Attachments from '../../share/attachment/Attachments.vue'
import DynamicBreadcrumb from '../../../share/mixins/DynamicBreadcrumb.js'
import History from '../../../../share/history/History.vue'
import TaskThirdMenu from '../../share/TaskThirdMenu/taskThirdMenu.js'
import moment from 'moment'
import _ from 'lodash'
import Messages from './messages/Messages.vue'
import BlockingLoader from '@/components/share/BlockingLoader.vue'
import TaskHeader from '../../share/TaskHeader/TaskHeader.vue'
import customSecondMenuMixin from '../../share/mixins/customSecondMenuMixin.js'
import clientObjectMixin from '../../share/mixins/clientObjectMixin.js'
import tabsDistanceMixin from '@/components/share/mixins/tabsDistanceMixin.js'

export default {
  name: "complaintTaskDetails",
  components: {
    TaskHeader,
    BlockingLoader,
    Messages,
    Tabs,
    ChangeStateModal,
    Attachments,
    History,
  },
  mixins: [
    DynamicBreadcrumb,
    Page,
    TaskThirdMenu,
    customSecondMenuMixin,
    clientObjectMixin,
    tabsDistanceMixin
  ],
  data() {
    return {
      headerTitle: {title: 'Reklamacja'},
      taskNumber: '',
      path: '',
      task: null,
      taskData: null,
      taskFilePath: '',
      dataLoaded: false,
      filesCount: 0,
      tabsData: null,
      changeStateVisible: false,
      service: this.$route.params.service,
      taskId: this.$route.params.taskId,
      simpleTransition: false,
      loadingData: false,
      relatedUsers: []
    }
  },
  computed: {
    secondMenu() {
      return [
        {
          event: {name: `${this.service}:main:changeState`},
          icon: 'skip-forward',
          label: 'Zmień status',
          acl: {security: false}
        }
      ]
    },
    categoryGroup() {
      return this.$getCategoryGroupNew(this.clientObject, this.$route.params.taskCategoryId)
    },
    categoryName() {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).name
    },
    categoryLabel() {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).label
    },
    categoryId() {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).id
    },
    workflowId() {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].id
    },
    workflowName() {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].name
    },
    events() {
      return {
        changeState: `${this.service}:main:changeState`,
        changeAssignee: `${this.service}:main:changeAssignee`,
        addComment: `${this.service}:main:addComment`,
        simpleTransition: `${this.service}:main:simpleTransition`,
      }
    },
    allTasksFilePaths() {
      if (this.taskNumber !== '') {
        let filePaths = [
          this.path + '/' + _.replace(this.taskNumber, '/', '_')]
        return filePaths
      } else {
        return false
      }
    },
    taskHeaderData() {
      return [
        [
          [this.task.state ? this.task.state.label : 'b.d.', 'Status', `${this.task.id}-header-state`],
          [this.task.createdAt ? this.task.createdAt : 'b.d.', 'Utworzenie', `${this.task.id}-header-createdAt`],
          [this.task.lastClosedAt ? this.task.lastClosedAt  : 'b.d.', 'Zamknięcie', `${this.task.id}-header-lastClosedAt`],
          [this.assistant && this.assistant.fullname ? this.assistant.fullname : 'b.d.', 'Asystent',  `${this.task.id}-header-assistant`],
          [this.analyst && this.analyst.fullname ? this.analyst.fullname : 'b.d.', 'Analityk',  `${this.task.id}-header-analyst`],
          [this.appraiser && this.appraiser.fullname ? this.appraiser.fullname : 'b.d.', 'Rzeczoznawca',  `${this.task.id}-header-appraiser`],
        ],
        []
      ]
    },
    relatedTasks() {
      if (!(this.task.additionalData && this.task.additionalData && this.task.additionalData.relatedTasks)) {
        return []
      }
      return this.task.additionalData.relatedTasks
    },
    analyst() {
      return this.findUserWithRole("ROLE_ANALITYK");
    },
    appraiser() {
      return this.findUserWithRole("ROLE_RZECZOZNAWCA_ZEWN");
    },
    assistant() {
      return this.findUserWithRole("ROLE_ASYSTENT_BWN");
    },
  },
  watch: {
    task: function (newTask) {
      this.taskNumber = newTask.number
      this.path = newTask.createdAt.substr(0, 7).replace('-', '')
    }
  },
  mounted() {
    this.$events.on(this.events.changeState, this.changeState)
    this.$events.on(this.events.addAttachment, this.addAttachment)
    this.$events.on(this.events.addComment, this.addComment)
    this.$events.on(this.events.changeAssignee, this.changeAssignee)
    this.$events.on(this.events.simpleTransition, this.doSimpleTransition)
    this.loadTaskData()
    this.getRelatedTaskData()
    this.getTabs()
  },
  methods: {
    getTabs() {
      this.tabsData = [
        [
          'messages',
          'Komunikacja',
          true,
          ['storage.get_files']
        ],
        [
          'history',
          'Historia',
          false,
          ['history.get_subject_changes']
        ],
        [
          'attachments',
          'Załączniki',
          false,
          ['storage.get_files']
        ]
      ]
    },
    getType(subject, field) {
      return this.$route.params.taskCategory
    },
    setFormValidity(validity) {
      this.isFormValid = validity
    },
    changeState() {
      this.changeStateVisible = true
    },
    getLoadTaskDataUrl() {
      return `/tasks/${this.$route.params.taskId}?client=${this.clientObject.name}&${this.categoryName}=true`
    },
    getLoadRelatedTaskDataUrl() {
      return `/tasks/${this.$route.params.taskId}/related-users?complaint=true`
    },
    loadTaskData() {
      this.loadingData = true
      api.request(this.service, 'get', this.getLoadTaskDataUrl())
        .then((response) => {
          this.toggleLoading()
          this.task = response.data.task
          this.taskNumber = this.task.number
          this.path = moment(this.task.createdAt).format('YYYYMM')
          if (this.taskNumber && this.taskNumber !== '') {
            this.taskFilePath = this.path + '/' +
              this.taskNumber.split('/').join('_')
          }
          delete response.data.task
          this.taskData = response.data
          this.getTabs()

          const title = this.task.number ? `${this.task.number}` : `${this.task.id}`
          document.title = title

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(`complaints`, `${this.$getClient(this.service, this.client).label} - ${this.categoryLabel}`)
          this.addDynamicBreadcrumbLabel(this.$route.name, title)
          this.reactToTabChange('messages')

          this.dataLoaded = true
          this.$setCustomSecondMenuButtons()

          if (this.task.state.final) {
            this.$events.$emit('dashboard:submenu:taskInFinalState', true)
          }
          this.loadNumberOfAttachments()
          this.loadingData = false
        })
        .catch((error) => {
          this.loadingData = false
          if (error.response && error.response.data && error.response.data.error && error.response.data.error.message.includes('access_denied')) {
            this.$router.push({name: `${this.service}_task_listing`})
            this.toggleLoading()
            this.$notify({
              type: 'error',
              title: 'Przekierowano',
              text: 'Brak dostępu do zlecenia'
            })
          } else {
            this.toggleLoading()
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie można załadować danych'
            })
          }
        })
    },
    getRelatedTaskData() {
      api.request(this.service, 'get', this.getLoadRelatedTaskDataUrl())
      .then((response) => {
          this.toggleLoading()
          this.relatedUsers = response.data
        })
        .catch((error) => {
          this.loadingData = false
          this.errorNotify(error)
        })
    },
    findUserWithRole(role) {
      return this.relatedUsers.find(
        (relatedUser) => relatedUser.role === role
      );
    },
    setCustomButtons() {
      this.$events.$emit("add:custom:secondMenu:buttons",
        this.secondMenu
      )
    },
    async loadNumberOfAttachments() {
      if (this.taskNumber && this.taskNumber !== '') {
        try {
          let {data} = await api.request('storage', 'get',
            `/download/${this.service}?format=names&filePaths=${this.allTasksFilePaths.join()}`)
          this.filesCount = data.length
          this.tabsData = this.tabsData.map(
            ([tag, title, ...params]) => tag === 'attachments' ? [
              tag,
              `Załączniki ${this.filesCount !== 0 ? '(' + this.filesCount +
                ')' : ''}`,
              ...params] : [tag, title, ...params])
        } catch (e) {
          console.error(e)
        }
      }
    },
    addComment() {
      this.openCommentModal = true
    },
    formatDate(date, format) {
      return moment(date).format(format)
    },
    changeAssignee() {
      if (!this.task.assignee) {
        this.$notify({
          type: 'info',
          text: 'Zmiana odpowiedzialnego w tym statusie nie jest możliwa'
        })
        return
      }
      this.changeAssigneeModalVisible = true
    },
    doSimpleTransition() {
      this.simpleTransition = true
    },
    reactToTabChange(tabName) {
      this.tabsData = this.tabsData.map(tab => tab[0] === tabName ? (tab.map((tabProp, index) => index === 2 ? (tabProp = true) : tabProp)) : (tab.map((tabProp, index) => index === 2 ? (tabProp = false) : tabProp)))
      let menu = []
      if (this.tabThirdMenu.hasOwnProperty(tabName)) {
        menu = this.tabThirdMenu[tabName]
      }
      this.$events.$emit('dashboard:menu:taskTab', menu)
    }
  }
}

</script>
