export default {
  computed: {
    activeTab () {
      return this.tabsData.find(tab => tab[2] === true)[0]
    },
    tabThirdMenu () {
      return {
        fv: [
          {
            event: { name: `${this.service}:main:addInvoice` },
            icon: 'plus',
            label: 'Dodaj fakturę',
            acl: { service: this.service, action: 'put_invoice' }
          },
          {
            event: { name: `${this.service}:main:deleteInvoice` },
            icon: 'minus',
            label: 'Usuń fakturę',
            acl: { service: this.service, action: 'delete_invoices' }
          },
        ],
        subtasks: this.subtasksCondition,
        payments: this.paymentsCondition,
        formularz: this.formButtons
      }
    },
    subtasksCondition () {
      if (this.task && this.task.id !== 0 && this.task.state && !this.task.state.initial) {
        return [
          {
            event: { name: `${this.service}:main:addAppraisalTask` },
            icon: 'plus',
            label: 'Dodaj podzlecenie',
            acl: { service: this.service, action: this.$isWithClients(this.service) ? `post_task__create-appraisal__${this.$route.params.client}` : 'post_appraisal_task' }
          }
        ]
      }
    },
    paymentsCondition () {
      if (this.task && this.task.id !== 0 && this.task.state && !this.task.state.initial) {
        return [
          {
            event: { name: `${this.service}:main:addPaymentTask` },
            icon: 'plus',
            label: 'Dodaj wypłatę'
            // TODO: add proper ACL action name
            // acl: {service: 'intgen', action: 'put_main_task'}
          }
        ]
      }
    },
    generateDocumentConditions () {
      if (this.task && this.task.state && !this.task.state.initial) {
        return [
          {
            event: { name: `${this.service}:main:openGenerateDocumentModal` },
            icon: 'filepdf',
            label: 'Generuj pismo',
            acl: { service: this.service, action: 'post_document' }
          }
        ]
      }
    },
    cloneTaskConditions () {
      return [
        {
          event: { name: `${this.service}:main:cloneTask` },
          icon: 'copy',
          label: 'Kopiuj zlecenie',
          acl: { service: this.service, action: this.$isWithClients(this.service) ? `post_task__copy__${this.$route.params.clientSymbol}` : `post_${this.workflowName}_task_copy` }
        }
      ]
    },
    formButtons () {
      let buttons = [this.generateDocumentConditions, this.cloneTaskConditions]
      return buttons.filter(element => element).map(button => button[0])
    }
  }
}
