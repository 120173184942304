<template>
  <div
    class="col-xs-8 order-message"
    :class="message.isAnswer ? 'order-message-answer' : null"
    :dark="message.isAnswer"
  >
    <div class="order-message-metadata">{{ message.author }} | {{ message.createdAt }} | napisał(a):</div>

    <div class="order-message-content">

      <div>{{message.content}}</div>
    </div>
    <div class="order-message-attachment-button-container" :class="{'order-message-attachment-button-container-end': message.isAnswer}">
      <button v-if="message.attachmentUuids && message.attachmentUuids.length > 0" class="zord-btn btn zord-btn-primary btn-primary col-xs-1"  @click="download">
        <i class="feather-attach"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
name: "Message",
  props: {
  message: {required: true, type: Object}
  },
  methods: {
    ...mapActions([
      'actionDownload',
    ]),
    download () {
      let uuids = this.message.attachmentUuids
      this.actionDownload({uuids: uuids, service: this.$route.params.service, taskNumber: `Wiadomość-${this.message.id}-${this.message.createdBy}`, $notify: this.$notify})
    },
  }
}
</script>

<style scoped>
.order-message {
  border: 1px solid rgba(66, 165, 246, 0.2);
  border-radius: 3px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
  margin: 5px;
  background: white;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.order-message.order-message-answer {
  background-color: #42a5ff;
}

.order-message-content {
  word-break: break-word;
}

.order-message-attachment-button-container {
  display: flex;
}

.order-message-attachment-button-container-end {
  justify-content: flex-end;
}

.order-message-attachment-button-container button {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
</style>
