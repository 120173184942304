<template>
  <section>
    <div v-if="messages" class="messages-list">
      <template v-for="(message, index) in messages">
        <Message :class="{'answer': message.isAnswer, 'bank-message': !message.isAnswer}" :message="message"
                 :key="index"></Message>
      </template>
    </div>
    <div v-else>Nie ma aktualnie żadnych wiadomości</div>
    <blocking-loader :show="loadingMessages">Wczytywanie wiadomości</blocking-loader>
  </section>

</template>

<script>
import Message from './Message.vue'
import api from '@/api'
import ErrorNotify from '@/components/share/mixins/ErrorNotify.js'
import moment from 'moment'
import BlockingLoader from "@/components/share/BlockingLoader";

export default {
  name: 'Messages',
  components: {BlockingLoader, Message},
  mixins: [
    ErrorNotify
  ],
  props: {
    service: {required: true},
    task: {}
  },
  data() {
    return {
      events: {
        newMessage: ''
      },
      messages: null,
      loadingMessages: false
    }
  },
  mounted() {
    this.getMessages()
  },
  methods: {
    async getMessages() {
      this.loadingMessages = true
      const sortByDateDec = (a, b) => {
        return moment(b) - moment(a)
      }
      try {
        // const { data } = await api.request(this.service, 'get', `/order-messages/${this.task.id}`)

        let dataToSend = {
          tasksIds: [this.task.id]
        }
        dataToSend['order_message'] = true
        dataToSend['complaint'] = true

        let {data} = await api.request(this.service, 'post', `comments/search`, dataToSend)

        this.messages = data.sort((a, b) => sortByDateDec(a.createdAt, b.createdAt))
        this.loadingMessages = false
      } catch (e) {
        this.loadingMessages = false
        this.errorNotify(e)
      }


    }
  }
}
</script>

<style scoped>
.messages-list {
  display: flex;
  flex-direction: column;
}

.answer {
  align-self: flex-end;
}

.bank-message {
  align-self: flex-start;
}
</style>
