<template>
  <div>
    <h2 class=" tab-header">{{$t('pages.taskDetails.tabs.history')}}</h2>
    <div>
      <grid :source="gridDataSource"
            :name="gridName"
            :columns="gridColumns"
            :defaultOrder="[[4, 'asc']]"
            ref="historyDatatable"
      >
      </grid>
    </div>
    <blocking-loader
      :show="showLoader"
    ></blocking-loader>
  </div>
</template>

<script>
import $ from 'jquery'
import BaseListing from '../../bundles/share/ListingWithEditMode'
import BlockingLoader from '../BlockingLoader'

export default {
  components: { BlockingLoader },
  mixins: [
    BaseListing
  ],
  props: {
    taskUuid: { type: String, required: true },
    service: { type: String, required: true }
  },
  data () {
    return {
      events: null,
      showLoader: false,
      headerTitle: { title: 'Historia zmian zlecenia', description: 'Lista' },
      gridName: 'grid_task_main_history',
      gridColumns: [
        {
          className: 'details-control',
          searchable: false,
          orderable: false,
          render (data, type, row, meta) {
            return (
              `<i class="feather-chevron-right padded pull-left"></i>`
            )
          }
        },
        { id: 0, data: 'propertyLabel', title: 'pages.taskDetails.history.propertyLabel', orderable: false },
        { id: 1, data: 'prevValue', title: 'pages.taskDetails.history.prevValue', orderable: false },
        { id: 2, data: 'value', title: 'pages.taskDetails.history.value', orderable: false },
        { id: 3, data: 'user', title: 'pages.taskDetails.history.user', orderable: false },
        { id: 4, data: 'createdAt', title: 'pages.taskDetails.history.createdAt', orderable: false }
      ]
    }
  },
  computed: {
    gridDataSource () {
      return {
        service: 'history',
        url: `/${this.service}/subjects/task/${this.taskUuid}/changes`
      }
    }
  },
  created () {
    this.showLoader = false
    this.getInitEvents()
  },
  mounted () {
    this.expandRowListener()
    this.$events.on(this.events.historyLoaded, this.hideLoader)
  },
  beforeDestroy () {
    $(`#${this.gridName}`).off('click', 'i.pull-left', this.expandRow)
  },
  methods: {
    getInitEvents () {
      this.events = {
        historyLoaded: `${this.gridName}:dataTableLoaded`
      }
    },
    hideLoader () {
      this.showLoader = false
    },
    format (d, vm) {
      // `d` is the original data object for the row\
      if (d.subData && d.subData.length) {
        return d.subData.map((el) => {
          return (
            `
              <div class="col-xs-12 bordered">
                <div class="col-xs-2 text-bold align-right history-values">${el.property || '-'}</div>
                <div class="col-xs-2 text-bold align-right history-values">${el.prevValue || '-'}</div>
                <div class="col-xs-8 text-bold align-right history-values">${el.value || '-'}</div>
                <div class="borderline col-xs-9" />
              </div>
            `
          )
       })
      }
      return '' +
        '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">'+ vm.$t('pages.taskDetails.history.propertyLabel') + '</div>' + '<div class="history-values col-xs-7">' + d.propertyLabel + ' ' + (d.prevValue || '-') + '</div><div class="borderline col-xs-9"></div></div>' +
        '' +
        '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">' + vm.$t('pages.taskDetails.history.prevValue') + '</div>' + '<div class="history-values col-xs-7">' + (d.prevValue || '-') + '</div><div class="borderline col-xs-9"></div></div>' +
        '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">'+ vm.$t('pages.taskDetails.history.value') + '</div>' + '<div class="history-values col-xs-7">' + (d.value || '-') + '</div><div class="borderline col-xs-9"></div></div>' +
        ''
    },
    expandRow (e) {
      let vm = this
      let tr = $(e.currentTarget).closest('tr')
      let row = vm.$children[0].table.row(tr)
      let icon = $(e.currentTarget).closest('i.pull-left')
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide()
        tr.removeClass('shown')
        icon.removeClass('down')
        icon.addClass('right')
      } else {
        // Open this row
        row.child(vm.format(row.data(), vm)).show()
        tr.addClass('shown')
        icon.removeClass('right')
        icon.addClass('down')
      }
    },
    expandRowListener () {
      $(`#${this.gridName}`).on('click', 'i.pull-left', this.expandRow)
    }
  }
}
</script>
<style>
    .history-values {
        word-break: break-all;
        white-space: normal;
    }
    .padded {
        padding: 10px;
        cursor: pointer;
    }
    .align-right {
        text-align: right;
    }
    .align-left {
        text-align: left;
    }
    .right {
        transition: all 0.5s;
    }
    .borderline {
        height: 1px;
        background-color: #42a5f6;
        margin-left: 2.333%;
    }
    .down {
        transform: rotate(90deg);
        transition: all 0.5s;
    }
</style>
